import { useRecentStore, useUserStore } from '@/stores';
import { RouteName } from '@/enums';

export function useContact() {
  const userStore = useUserStore();
  const recentStore = useRecentStore();

  function addRecentDirectory(id: string) {
    const directory = userStore.directory(id);

    if (directory?.favorite) return;

    const contact = userStore.getImportedContactsByDirectoryId(Number(id));

    if (contact) {
      recentStore.removeRecentWithTypeAndId('directory', id);

      recentStore.recent.unshift({
        id: Number(id),
        name: `${contact.firstName} ${contact.lastName}`.trim(),
        type: 'directory',
        routeName: RouteName.Directory,
      });
    }
  }

  function addRecentContact(number: string) {
    const contact = userStore.getImportedContactByNumber(number);

    if (contact) {
      if (contact.isDirectory) {
        addRecentDirectory(String(contact.directory.id));
        return;
      }

      if (contact.favorite) return;

      recentStore.removeRecentWithTypeAndId('contact', contact.id.toString(10));

      recentStore.recent.unshift({
        id: contact.id,
        name: `${contact.firstName} ${contact.lastName}`.trim(),
        type: 'contact',
        routeName: RouteName.Contact,
      });
    }
  }

  return {
    addRecentDirectory,
    addRecentContact,
  };
}
